import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../../images/vf_logo_white.svg"
import backgroundVideo from "./bg-video.mp4"
import "./header.scss"

const Header = ({ siteTitle, isHome }) => (
  <header className={"site-header " + (isHome ? "isHome" : "")}>
    <div className="background">
      <video className="background-item" muted loop="loop" autoPlay>
        <source src={backgroundVideo} type="video/mp4" />
      </video>
    </div>
    <div className="container">
      <div className="row d-flex justify-content-between align-items-end">
        <div className="ml-3">
          <h1 style={{ margin: 0 }}>
            <Link to="/">
              <Logo className="logo" alt={siteTitle} />
            </Link>
          </h1>
        </div>
        <div className="mr-3">
          <nav className="main-nav">
            <a href="/">Accueil</a>
            <a href="/produits">Produits</a>
            <a href="/distributeurs">Distributeurs</a>
            <a href="/acces-pro">Accès pro</a>
            <a href="/contact">Contactez-nous</a>
          </nav>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
