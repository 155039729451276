import React from "react"
import Logo from "../../images/vf_logo.svg"
import "./footer.scss"

const Header = () => (
  <footer className="site-footer pt-5 small">
    <div className="container">
      <div className="row">
        <div className="col-md-4 mb-5 text-center text-md-left d-flex flex-column">
          <p>
            <strong>Vapor Flavour</strong>
            <br />
            Rte du Petit Bois 5<br />
            1727 Corpataux
          </p>
          <p className="mt-auto">
            <strong>Téléphone</strong>
            <br />
            <a href="tel:0041 79 222 87 34">079 222 87 34</a>
          </p>
          <p className="mb-0">
            <strong>Email</strong>
            <br />
            t.wyss@baselab.ch
          </p>
        </div>
        <div className="col-md-4 text-center d-flex mb-5">
          <Logo className="logo m-auto" />
        </div>
        <div className="col-md-4 text-center text-md-right d-flex flex-column mb-5">
          <div>
            <p>
              Web by <a href="https://www.minguely.ch">jminguely</a>
              <br />
              <br />
            </p>
          </div>
          <div className="mt-auto">
            <p className="mb-0">© {new Date().getFullYear()} - Vapor Flavor</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Header
